import styled from 'styled-components'
import { theme, media } from '../../../helpers/theme'
import Link from 'gatsby-link'

const Policy = styled.div`
  margin: ${theme.space[10]}px auto;
  padding: ${theme.space[5]}px;
  ${media(768)} {
    width: 750px;
  }

  ${media(992)} {
    width: 970px;
  }
  ${media(1200)} {
    width: 1170px;
  }
  color: ${theme.colors.font.darkGray};
  display: flex;
  flex-direction: column;
`
Policy.title = styled.span`
  font-size: ${theme.font['3xl']};
  padding: ${theme.space[8]}px 0;
  color: black;
`

Policy.paragraph = styled.span`
  font-size: ${(props) => (props.sm ? theme.font['sm'] : theme.font['md'])};
  padding: ${theme.space[2]}px 0;
`

Policy.inlineLink = styled(Link)`
  color: ${theme.colors.font.green};
  text-decoration: none;
  font-size: inherit;
  font-style: inherit;
  &:hover {
    cursor: pointer;
    color: ${theme.colors.font.darkGreen};
  }
  :before {
    content: ' ';
  }
`

Policy.list = styled.ol`
  list-style-type: decimal;
  padding-left: ${theme.space[8]}px;
`

Policy.listChild = styled.li`
  padding: ${theme.space[3]}px 0;
  font-size: ${(props) =>
    props.mainTitle ? theme.font['xl'] : theme.font['base']};
  font-weight: ${(props) => (props.mainTitle ? 600 : 400)};
  text-transform: ${(props) => (props.mainTitle ? 'uppercase' : 'none')};
`
Policy.updatedText = styled.span`
  margin-top: ${theme.space[6]}px;
  font-size: ${theme.font['sm']};
  color: ${theme.colors.font.darkGray};
`


export default Policy
