import React from 'react'
import Policy from './copyright.styles'

const copyright = () => {
  return (
    <Policy>
      <Policy.title>Copyright Infringement Policy</Policy.title>
      <Policy.paragraph>
        Sitey takes intellectual property rights very seriously and it is our
        policy to respond to clear notices of alleged copyright infringement.
        This Copyright Infringement Policy describes the information that should
        be present in these notices. It is designed to make submitting notices
        of alleged infringement to us as straightforward as possible while
        reducing the number of notices that we receive that are fraudulent or
        difficult to verify.
      </Policy.paragraph>
      <Policy.title>Copyright Infringement Policy</Policy.title>
      <Policy.paragraph sm>
        To file a notice of alleged infringement with us, you must provide a
        written notice that includes your full contact information and sets
        forth the items specified below.
      </Policy.paragraph>
      <Policy.list>
        <Policy.listChild>
          The name, address, phone number, email address (if available) and
          physical or electronic signature of the copyright owner or a person
          authorized to act on the copyright owner’s behalf;
        </Policy.listChild>
        <Policy.listChild>
          Identification of the copyrighted work(s);
        </Policy.listChild>
        <Policy.listChild>
          Identification of the infringing material you are asking us to remove
          or disable, and the Internet location of the infringing material;
        </Policy.listChild>
        <Policy.listChild>
          A statement that you have a good faith belief that use of the disputed
          material is not authorized by the copyright owner, its agent or the
          law; and
        </Policy.listChild>
        <Policy.listChild>
          A statement that the information in the complaint is accurate, and
          under penalty of perjury, that you are authorized to act on behalf of
          the owner of an exclusive right that is allegedly infringed.
        </Policy.listChild>
        <Policy.listChild>Your signature</Policy.listChild>
      </Policy.list>
      <Policy.paragraph sm>
        Please email the signed notification to{' '}
        <Policy.inlineLink href='mailto:abuse@Sitey.com'>
          abuse@Sitey.com
        </Policy.inlineLink>
        .
      </Policy.paragraph>
      <Policy.paragraph sm>
        Alternatively, you may mail the signed notification to the following
        address:
      </Policy.paragraph>
      <Policy.paragraph sm>
        Newfold Digital <br />
        5335 Gate Pkwy. <br />
        2nd Floor <br />
        Jacksonville, FL 
        <br />
        32256 <br />
        USA
      </Policy.paragraph>
      <Policy.paragraph sm>
        Please note that you will be liable for damages (including costs and
        attorneys' fees) if you materially misrepresent that material is
        infringing your copyright. Accordingly, if you are not sure whether
        material available online infringes your copyright, we suggest that you
        first contact an attorney.
      </Policy.paragraph>
      <Policy.paragraph sm>
        Sitey may respond to take-down notices by removing or disabling access
        to the allegedly infringing material and/or by terminating services. If
        we remove or disable access in response to such a notice, we will make a
        good-faith attempt to contact the owner or administrator of the affected
        site or content.
      </Policy.paragraph>
      <Policy.paragraph sm>
        Sitey may document notices of alleged infringement it receives and/or on
        which we action is taken. As with all legal notices, a copy of the
        notice may be made available to the public and sent to one or more third
        parties who may make it available to the public.
      </Policy.paragraph>
      <Policy.updatedText>Last updated: October 07, 2022</Policy.updatedText>
    </Policy>
  )
}

export default copyright
